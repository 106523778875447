// @ts-nocheck generated by chatgtp..what could go wrong?
import type { Chat } from 'client/model/chat'

export function dateSorting(items: Chat[]) {
  // Initial sort by last updated
  items.sort((a, b) => {
    const aLastMessage = a.messages[a.messages.length - 1]
    const bLastMessage = b.messages[b.messages.length - 1]

    const aLastMessageDate = aLastMessage
      ? new Date(aLastMessage.created_at)
      : new Date(a.created_at)
    const bLastMessageDate = bLastMessage
      ? new Date(bLastMessage.created_at)
      : new Date(b.created_at)

    return bLastMessageDate.getTime() - aLastMessageDate.getTime()
  })

  const groupedItems = {
    today: [],
    yesterday: [],
    prev7Days: [],
    prev30Days: [],
    byMonth: {},
    byYear: {},
  }

  const todayDate = new Date()
  const yesterdayDate = new Date()
  yesterdayDate.setDate(yesterdayDate.getDate() - 1)

  for (const item of items) {
    const lastMessageDate = item.messages[item.messages.length - 1]
      ? new Date(item.messages[item.messages.length - 1].created_at)
      : new Date(item.created_at)
    const diffDays = Math.floor(
      (todayDate - lastMessageDate) / (1000 * 60 * 60 * 24),
    )
    if (diffDays === 0) {
      groupedItems.today.push(item)
    } else if (diffDays === 1) {
      groupedItems.yesterday.push(item)
    } else if (diffDays <= 7) {
      groupedItems.prev7Days.push(item)
    } else if (diffDays <= 30) {
      groupedItems.prev30Days.push(item)
    } else {
      const year = new Date(item.created_at).getFullYear().toString()
      if (year === todayDate.getFullYear().toString()) {
        const monthName = new Date(item.created_at).toLocaleString('default', {
          month: 'long',
        })
        const monthYearKey = `${monthName}`
        if (!groupedItems.byMonth[monthYearKey]) {
          groupedItems.byMonth[monthYearKey] = []
        }
        groupedItems.byMonth[monthYearKey].push(item)
      } else {
        if (!groupedItems.byYear[year]) {
          groupedItems.byYear[year] = []
        }
        groupedItems.byYear[year].push(item)
      }
    }
  }
  return groupedItems
}
