import { getUser } from '@northvolt/snowflake'
import { useInsights } from 'contexts/InsightsContext'
import styles from 'styles/welcoming.module.scss'

export default (): JSX.Element => {
  const user = getUser()
  const givenName = user?.given_name
  const { hasInsights, reload } = useInsights()
  const date = new Date().toLocaleDateString('sv', {
    timeZone: 'Europe/Stockholm',
  })

  const currentHour = new Date().getHours()
  let greeting = 'Good Morning'
  if (currentHour >= 12 && currentHour < 17) {
    greeting = 'Good Afternoon'
  } else if (currentHour >= 17) {
    greeting = 'Good Evening'
  }

  return (
    <>
      <div className={styles.welcome}>
        <span className={styles.welcome__date}>{date}</span>
        <span className={styles.welcome__intro}>{greeting},</span>
        <div className={styles.welcome__you}>{givenName}</div>
        <span className={styles.welcome__outro}>
          {hasInsights ? (
            'Here are some insights to start your day -- '
          ) : (
            <span>
              We hope you're having a great day --{' '}
              <span className={styles.welcome__reload} onClick={() => reload()}>
                {' '}
                check today's insights again
              </span>
            </span>
          )}
        </span>
      </div>
    </>
  )
}
