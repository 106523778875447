import { ForceRefresh, Verify } from '@northvolt/snowflake'
import { Button, Menu, MenuItem, Tooltip, Typography } from '@northvolt/ui'
import { useCreateChat, useGetUserAssistants } from 'client/wattson-client'
import { useEffect, useState } from 'react'

import type { AssistantEnum } from 'client/model/assistantEnum'

export default function NewChatButton({
  handleDrawerClose,
}: {
  handleDrawerClose: () => void
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const chatCreator = useCreateChat()

  const { isLoading, data } = useGetUserAssistants()
  const assistants = data?.data || []

  useEffect(() => {
    if (chatCreator.isSuccess && chatCreator.data?.data?.id) {
      const { id } = chatCreator.data.data
      handleDrawerClose()
      handleClose()
      window.location.replace(`/chat/${id}`)
    }
  }, [chatCreator.isSuccess])

  async function createNewChat(assistant: AssistantEnum) {
    //@ts-expect-error yes there is a loader.
    if (chatCreator.isLoading) {
      return
    }
    try {
      await Verify()
    } catch (error) {
      await ForceRefresh()
    }
    chatCreator.mutate({ assistant })
  }

  return (
    <>
      <Button
        onClick={handleClick}
        aria-controls={open ? 'menu-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='outlined'
        color='secondary'
        disabled={
          isLoading ||
          // @ts-expect-error yes there is a loader.
          chatCreator.isLoading ||
          assistants.length < 1
        }
        fullWidth>
        {
          // @ts-expect-error yes there is a loader.
          isLoading || chatCreator.isLoading ? 'Loading..' : 'New Chat'
        }
      </Button>

      <Menu
        id='menu-menu'
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}>
        {assistants.map((assistant, index) => (
          <Tooltip
            title={
              <>
                <Typography variant='subtitleMedium'>
                  {assistant.name}
                </Typography>
                <Typography gutterBottom variant='bodySmall'>
                  {assistant.description}
                </Typography>
              </>
            }
            arrow
            placement='right'
            key={`assistant-${index}`}>
            <MenuItem
              divider={false}
              onClick={() => createNewChat(assistant.path)}
              sx={{ padding: 2, minWidth: 212 }}>
              <Typography variant='subtitleMedium'>{assistant.name}</Typography>
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </>
  )
}
