import { getUser } from '@northvolt/snowflake'
import * as Sentry from '@sentry/react'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'
import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import Layout from 'components/Layout'

export default function Root() {
  useEffect(() => {
    const user = getUser()

    if (!user) return

    mixpanel.identify(user.email)
    mixpanel.people.set({
      $name: `${user.given_name} ${user.family_name}`,
      $email: user.email,
      email: user.email,
    })
    mixpanel.people.set_once('First Login Date', new Date())

    Sentry.setUser({
      email: user ? user.email : 'unknown',
      name: `${user.given_name} ${user.family_name}`,
    })
  }, [])

  return (
    <>
      <Layout>
        <Outlet />
        <Analytics />
        <SpeedInsights />
      </Layout>
    </>
  )
}
