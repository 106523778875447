import type { InsightsContextType } from 'Types'
import type { Insight } from 'client/model'
import { useReadInsights } from 'client/wattson-client'
import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

const InsightsContext = createContext<InsightsContextType | undefined>(
  undefined,
)

const InsightsProvider = ({ children }: { children: ReactNode }) => {
  const { data } = useReadInsights()
  const insights: Insight[] = data?.data || []

  const drop = (id: number) => {
    const newInsights = content.filter(content => content.id !== id)
    hasInsightsSetter(newInsights.length > 0)
    contentSetter([...newInsights])
  }

  const reload = useCallback(() => {
    contentSetter([...insights])
    hasInsightsSetter(true)
  }, [insights])

  const clean = () => contentSetter([])

  const [content, contentSetter] = useState<Insight[]>([...insights])

  const [hasInsights, hasInsightsSetter] = useState<boolean>(
    content?.length > 0,
  )

  useEffect(() => {
    if (insights.length) reload()
  }, [insights])

  return (
    <InsightsContext.Provider
      value={{ hasInsights, drop, clean, content, reload }}>
      {children}
    </InsightsContext.Provider>
  )
}

const useInsights = () => {
  const insightsContext = useContext(InsightsContext)
  if (insightsContext === undefined) {
    throw new Error('useInsights must be used within an InsightsProvider')
  }
  return insightsContext
}

export { InsightsProvider, useInsights }
