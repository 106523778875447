import BoltIcon from '@mui/icons-material/Bolt'
import { Box, Stack, useTheme } from '@northvolt/ui'
import { motion } from 'framer-motion'
import type React from 'react'

interface AnimatedAvatarProps {
  isLoading: boolean
}

const AnimatedAvatar: React.FC<AnimatedAvatarProps> = ({ isLoading }) => {
  const theme = useTheme()
  const color = isLoading ? '#FFFFFF' : theme.palette.grey[800]
  return (
    <Stack sx={{ m: 0 }}>
      <Box
        sx={{
          width: '35px',
          height: '35px',
          backgroundColor: '#44a27a',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
          m: 0,
        }}>
        {isLoading && (
          <>
            <motion.div
              style={{
                backgroundColor: '#44a27a',
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                zIndex: 0,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              animate={{
                scale: [1, 1.3, 1.3, 1, 1],
                rotate: [0, 0, 180, 180, 0],
                borderRadius: ['50%', '25%', '25%', '10%', '50%'],
              }}
              transition={{
                duration: 1.5,
                ease: 'easeInOut',
                times: [0, 0.2, 0.5, 0.8, 1],
                repeat: Number.POSITIVE_INFINITY,
                repeatDelay: 1,
              }}
            />
            <BoltIcon
              sx={{
                color: color,
                fontSize: 26,
                zIndex: 1,
                padding: 0.25,
                transform: 'translate(0%, -100%)',
                width: '100%',
                height: '100%',
              }}
            />
          </>
        )}
        {!isLoading && (
          <BoltIcon
            sx={{
              color: color,
              padding: 0.25,
              fontSize: 26,
              width: '100%',
              height: '100%',
            }}
          />
        )}
      </Box>
      {isLoading && (
        <Box sx={{ m: 0, p: 0, width: '100%' }}>
          {'thinking'.split('').map((char, index) => (
            <motion.span
              key={`avatar-${index}`}
              style={{ display: 'inline-block', fontSize: 10 }}
              animate={{
                opacity: [0, 1],
              }}
              transition={{
                duration: 1.5,
                ease: 'easeInOut',
                times: [0, 0.1 * index],
                repeat: Number.POSITIVE_INFINITY,
                repeatDelay: 0,
                repeatType: 'mirror',
              }}>
              {char}
            </motion.span>
          ))}
        </Box>
      )}
    </Stack>
  )
}

export default AnimatedAvatar
