import Close from 'assets/icons/CloseIcon'
import type { SearchTag } from 'client/model'
import { useInsights } from 'contexts/InsightsContext'
import type { MouseEvent } from 'react'
import styles from 'styles/insights.module.scss'

/**
 * Insights section
 *
 * @returns {JSX.Element} Insights section
 */
export default (): JSX.Element => {
  const { hasInsights, drop, content } = useInsights()
  const dropCard = (e: MouseEvent<HTMLButtonElement>, id: number) => {
    e.currentTarget.parentElement?.classList.add(styles.closedCard)
    drop(id)
  }

  return (
    <>
      {hasInsights ? (
        <div className={styles.section}>
          {content?.map(({ id, title, search_tags, body }) => (
            <div key={`${id}-${title}`} className={styles.card}>
              <button
                title='close'
                type='button'
                className={styles.close}
                onClick={e => dropCard(e, id)}>
                <Close />
              </button>
              <h3 className={styles.title}>{title}</h3>
              <div className={styles.tags}>
                {[...search_tags]
                  .slice(0, 3)
                  .map(({ value, id }: SearchTag) => (
                    <div
                      className={styles.tag}
                      key={`${id}-${value}`}
                      data-hue={`${140 + Math.random() * 200}deg`}>
                      {value}
                    </div>
                  ))}
              </div>
              <p className={styles.text}>{body}</p>
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}
