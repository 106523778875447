import * as React from 'react'

import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import { Box, Drawer, Stack, useMediaQuery } from '@northvolt/ui'

import AppBar from 'components/AppBar'
import History from 'components/History'
import NewChatButton from 'components/NewChatButton/NewChatButton'

export const drawerWidth = 260

export default function Layout({ children }: { children: React.ReactNode }) {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
    defaultMatches: true,
  })

  const [sideMenu, sideMenuSetter] = React.useState(false)
  const [isClosing, isClosingSetter] = React.useState(false)

  const handleDrawerClose = () => {
    isClosingSetter(true)
    sideMenuSetter(false)
  }

  const handleDrawerTransitionEnd = () => {
    isClosingSetter(false)
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      sideMenuSetter(!sideMenu)
    }
  }

  const drawer = (
    <Stack
      direction='column'
      justifyContent='space-between'
      alignItems='flex-start'
      spacing={0}
      sx={{ height: '100%', position: 'relative' }}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Toolbar
          sx={{
            width: `${drawerWidth}px`,
            position: 'fixed',
            background: '#fff',
            zIndex: 10,
          }}>
          <NewChatButton handleDrawerClose={handleDrawerClose} />
        </Toolbar>
        <Toolbar />
        <History handleDrawerClose={handleDrawerClose} />
      </Box>
    </Stack>
  )

  return (
    <Box sx={{ display: 'flex' }}>
      <>
        <AppBar
          drawerWidth={drawerWidth}
          handleDrawerToggle={handleDrawerToggle}
        />

        <Box
          component='nav'
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label='mailbox folders'>
          {isDesktop ? (
            <Drawer
              variant='permanent'
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}
              open>
              {drawer}
            </Drawer>
          ) : (
            <Drawer
              variant='temporary'
              open={sideMenu}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                },
              }}>
              {drawer}
            </Drawer>
          )}
        </Box>
      </>

      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          paddingBottom: 5,
        }}>
        <Toolbar />
        <Stack spacing={2}>{children}</Stack>
      </Box>
    </Box>
  )
}
