import * as React from 'react'

import {
  Avatar,
  AppBar as Bar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@northvolt/ui'

import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import { Toolbar, useTheme } from '@mui/material'
import { SignOut, getUser } from '@northvolt/snowflake'
import { motion } from 'framer-motion'

import { InsightsProvider, useInsights } from 'contexts/InsightsContext'
import { Link } from 'react-router-dom'

export default function AppBar({
  drawerWidth,
  handleDrawerToggle,
}: {
  drawerWidth: number
  handleDrawerToggle: () => void
}) {
  const user = getUser()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const theme = useTheme()
  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleUserMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <Bar
      position='fixed'
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}>
      <Toolbar sx={{ paddingRight: 0 }}>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}>
          <MenuIcon />
        </IconButton>
        <InsightsProvider>
          <Link
            to={'/'}
            style={{ textDecoration: 'none', color: 'inherit' }}
            onClick={() => useInsights().reload()}>
            <Stack sx={{ ml: 1 }}>
              <motion.div
                initial={{ opacity: 0, scale: 5 }}
                animate={{ scale: 1, opacity: 1, from: 0, to: 1 }}
                transition={{ delay: 0 || 0.1, ease: 'easeIn' }}>
                <Typography variant='subtitleMedium' sx={{ fontSize: 24 }}>
                  Rome
                </Typography>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, translateX: -30 }}
                animate={{ translateX: 0, opacity: 1, from: 0, to: 1 }}
                transition={{ delay: 0.4, ease: 'easeOut' }}
                style={{ marginTop: '-10px' }}>
                <Typography variant='captionSmall'>
                  Powered by GigaWattson
                </Typography>
              </motion.div>
            </Stack>
          </Link>
        </InsightsProvider>

        <Box sx={{ flexGrow: 1 }} />
        <Link to='/help' style={{ textDecoration: 'none' }}>
          <IconButton>
            <LiveHelpOutlinedIcon
              style={{ color: theme.palette.secondary.light }}
            />
          </IconButton>
        </Link>
        <IconButton size='large' onClick={handleUserMenu} color='inherit'>
          <Avatar
            variant='rounded'
            sx={{
              width: '35px',
              height: '35px',
            }}>
            <Typography variant='bodySmall'>
              {user?.initials || 'You'}
            </Typography>
          </Avatar>
        </IconButton>

        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={Boolean(anchorEl)}
          onClose={handleUserMenuClose}>
          <MenuItem
            onClick={() => {
              SignOut()
              window.location.replace('/')
            }}>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </Bar>
  )
}
