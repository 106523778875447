import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@northvolt/ui'
import { motion } from 'framer-motion'
import { useRef, useState } from 'react'
import OutputDocument from './OutputDocument'
import type { ToolCallWithOutput } from './ToolUseSection'

interface SearchToolUseCardProps {
  toolData: ToolCallWithOutput
}

export default function SearchToolUseCard({
  toolData,
}: SearchToolUseCardProps) {
  const [detailOpen, setDetailOpen] = useState<boolean>(false)
  const descriptionElementRef = useRef<HTMLElement>(null)

  function outputContent() {
    if (toolData.output_json) {
      if (Array.isArray(toolData.output_json)) {
        return (
          <List>
            {toolData.output_json.map((document, index) => (
              <ListItem key={index}>
                <OutputDocument document={document} outputIdx={index} />
              </ListItem>
            ))}
          </List>
        )
      } else if (typeof toolData.output_json === 'object') {
        return JSON.stringify(toolData.output_json, null, 2)
      }
    } else if (toolData.output_text) {
      return toolData.output_text
    }
    return '...'
  }

  return (
    <Box
      component={motion.div}
      sx={{
        p: 1,
        border: 'solid 1px var(--nv-palette-grey-700)',
        borderRadius: '10px',
        height: '100%',
        maxHeight: '120px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'var(--nv-palette-grey-900)',
        },
      }}
      initial={{ opacity: 0, translateY: -25 }}
      animate={{ translateY: 0, opacity: 1, from: 0, to: 1 }}
      transition={{ delay: 0.1, ease: 'easeIn' }}
      onClick={() => setDetailOpen(!detailOpen)}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SearchIcon fontSize='small' />
        <Typography
          variant='subtitleSmall'
          sx={{ fontWeight: 'bold', ml: 0.5, lineHeight: 'normal' }}>
          Searching {toolData.name} for
        </Typography>
      </Box>
      <Box sx={{ m: 1 }}>
        {Object.entries(toolData.args).map(([key, value]) => (
          <Chip
            key={key}
            label={value}
            title={value}
            size='small'
            sx={{ m: 0.25 }}
          />
        ))}
      </Box>
      <Dialog
        open={detailOpen}
        onClose={() => setDetailOpen(false)}
        scroll='paper'
        fullWidth
        maxWidth='md'>
        <DialogTitle sx={{ alignItems: 'center', display: 'flex' }}>
          <SearchIcon fontSize='medium' sx={{ mr: 2 }} />
          {toolData.name}
          <IconButton
            onClick={() => setDetailOpen(false)}
            sx={{ position: 'absolute', right: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant='subtitleMedium'>Inputs</Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: 'solid 1px var(--nv-palette-grey-700)',
              p: 2,
              mb: 2,
            }}>
            {Object.entries(toolData.args).map(([key, value]) => (
              <Chip
                key={key}
                label={`${key} = ${value}`}
                title={value}
                size='small'
                sx={{ mr: 1 }}
              />
            ))}
          </Box>
          <Typography variant='subtitleMedium'>Outputs</Typography>
          <Box
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ p: 2, wordWrap: 'break-word' }}>
            {outputContent()}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
