import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import AutoModeIcon from '@mui/icons-material/AutoMode'
import { InputAdornment } from '@mui/material'
import { Box, IconButton, TextField, Typography } from '@northvolt/ui'
import mixpanel from 'mixpanel-browser'
import { useRef } from 'react'

interface ChatTextInputProps {
  isLoading: boolean
  sendMessage: (message: string) => void
  scrollToBottom: () => void
  stopStream?: () => void
  disabled?: boolean
}

const ChatTextInput: React.FC<ChatTextInputProps> = ({
  isLoading,
  sendMessage,
  scrollToBottom,
  stopStream,
  disabled,
}) => {
  const inputTextFieldRef = useRef<HTMLFormElement>(null)

  const submitButton = () => {
    const loadingButton = (
      <AutoModeIcon
        sx={{
          animation: 'spin 2s linear infinite',
          '@keyframes spin': {
            '0%': { transform: 'rotate(-360deg)' },
            '100%': { transform: 'rotate(0deg)' },
          },
        }}
      />
    )

    return (
      <IconButton
        type='submit'
        disabled={disabled}
        sx={{ background: '#ccc' }}
        onClick={() => {
          if (isLoading && stopStream) {
            mixpanel.track('stop-message')
            stopStream()
          } else if (inputTextFieldRef.current) {
            inputTextFieldRef.current.requestSubmit()
          }
        }}>
        {isLoading ? loadingButton : <ArrowUpwardIcon />}
      </IconButton>
    )
  }

  return (
    <Box
      component='footer'
      className='MuiBottomNavigation-root'
      sx={{ backgroundColor: 'transparent' }}>
      <Box sx={{ backgroundColor: 'transparent' }}>
        <Box
          sx={{
            backgroundColor: 'var(--nv-palette-background-default);',
            width: '100%',
            position: 'fixed',
            bottom: 1,
            right: 0,
            p: 2,
            paddingTop: 0,
            textAlign: 'center',
            left: { xs: 0, sm: `${240 / 2}px` },
            marginBottom: '-1px',
          }}>
          <form
            ref={inputTextFieldRef}
            onSubmit={event => {
              event.preventDefault()
              try {
                const target: any = event.currentTarget[0]
                if (target.value !== '') {
                  mixpanel.track('send-message')
                  sendMessage(target.value)
                  target.value = ''
                }
              } catch (error) {
                console.error('Error sending message', error)
              }
              scrollToBottom()
            }}>
            <TextField
              fullWidth
              type='text'
              label={isLoading ? 'Answering...' : 'Message GigaWattson...'}
              sx={{
                maxWidth: { xs: '100%', sm: '50%', md: '50%', lg: '700px' },
                borderColor: 'var(--nv-palette-grey-800)',
                backgroundColor: 'rgb(237, 237, 237)',
              }}
              disabled={disabled}
              autoComplete='off'
              color='secondary'
              multiline
              onKeyDown={e => {
                if (e.keyCode === 13 && !e.shiftKey) {
                  e.preventDefault()
                  if (inputTextFieldRef.current)
                    inputTextFieldRef.current.requestSubmit()
                }
              }}
              InputProps={{
                type: 'search',
                endAdornment: (
                  <InputAdornment position='end'>
                    {submitButton()}
                  </InputAdornment>
                ),
              }}
            />
          </form>
          <Typography
            variant='captionSmall'
            display='block'
            sx={{ fontSize: '10px' }}>
            GigaWattson can make mistakes. Consider checking important
            information.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ChatTextInput
