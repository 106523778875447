import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import { Box, Typography } from '@northvolt/ui'
import { motion } from 'framer-motion'
import type { ToolCallWithOutput } from './ToolUseSection'

import Plot from 'react-plotly.js'

interface PlottingToolOutputProps {
  toolData: ToolCallWithOutput
}

export default function PlottingToolOutput({
  toolData,
}: PlottingToolOutputProps) {
  const args = toolData.args
  const data = String(args?.plotly_data_json)
  const layout = String(args?.plotly_layout_json)

  return (
    <Box
      component={motion.div}
      sx={{
        p: 1,
        border: 'solid 1px var(--nv-palette-grey-700)',
        borderRadius: '10px',
        width: '100%',
        height: '100%',
      }}
      initial={{ opacity: 0, translateY: -25 }}
      animate={{ translateY: 0, opacity: 1, from: 0, to: 1 }}
      transition={{ delay: 0.1, ease: 'easeIn' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <AutoGraphIcon fontSize='small' />
        <Typography
          variant='subtitleSmall'
          sx={{ fontWeight: 'bold', ml: 0.5 }}>
          Plotting
        </Typography>
      </Box>
      <Plot
        data={JSON.parse(data)}
        layout={JSON.parse(layout)}
        style={{ width: '100%', height: '450px' }}
      />
    </Box>
  )
}
