import { Link, Paper, Typography } from '@northvolt/ui'

export default function Route() {
  return (
    <Paper elevation={1} sx={{ p: 4 }}>
      <Typography variant='headlineLarge'>Need Some Help?</Typography>

      <Typography variant='headlineMedium' sx={{ pt: 4 }}>
        Getting Started
      </Typography>
      <Typography variant='bodyMedium' sx={{ pt: 2 }}>
        Before using Rome, it is recommend that you check out our{' '}
        <Link
          href={
            'https://northvolt0.sharepoint.com/:p:/r/sites/MachineintelligenceSoftware/Shared%20Documents/General/ROME/External%20Presentations/Rome%20user%20onboarding%20deck.pptx?d=w0d6c6cc1f6af4885b5ce144bde95d457&csf=1&web=1&e=DlEXVf'
          }
          style={{ color: 'inherit' }}
          target='_blank'>
          Onboarding Guide
        </Link>
        .
      </Typography>

      <Typography variant='headlineMedium' sx={{ pt: 4 }}>
        Getting Help
      </Typography>
      <Typography variant='bodyMedium' sx={{ pt: 2 }}>
        Rome is in active development and you may run into issues. If so, please
        post in our{' '}
        <Link
          href={
            'https://teams.microsoft.com/l/channel/19%3A5ad0a544b4834acbb8dafd7cb3c4bf10%40thread.tacv2/Rome?groupId=4093c19b-98c0-456c-9f2c-6945505929b4&tenantId=706c5db9-5278-483b-b622-70084f823a12'
          }
          style={{ color: 'inherit' }}
          target='_blank'>
          Teams Channel
        </Link>{' '}
        and we will get back to you as soon as possible.
      </Typography>
    </Paper>
  )
}
