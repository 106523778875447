import { Avatar, Box, Stack, Typography } from '@northvolt/ui'

import AnimatedAvatar from './AnimatedAvatar'

import { highlighter } from '@nlux/highlighter'
import '@nlux/highlighter/dark-theme.css'
import '@nlux/themes/nova.css'
import { parseMdSnapshot } from '@nlux/markdown'
import type { StreamingChatMessage } from 'components/Chat'
import ToolUseSection from 'components/ToolUse/ToolUseSection'
import parse from 'html-react-parser'

interface MessageProps {
  message: StreamingChatMessage
  initials: string
  isLoading: boolean
  streamingContentRef?: React.RefObject<HTMLDivElement>
}

const Message: React.FC<MessageProps> = ({
  message,
  initials,
  isLoading,
  streamingContentRef,
}) => {
  const getAvatar = () => {
    if (message.role === 'human') {
      return (
        <Avatar
          sx={{
            width: '35px',
            height: '35px',
            display: { xs: 'none', md: 'flex' },
            backgroundColor: 'var(--nv-palette-Avatar-defaultBg)',
          }}>
          <Typography variant='bodySmall'>{initials || 'You'}</Typography>
        </Avatar>
      )
    }
    return <AnimatedAvatar isLoading={isLoading} />
  }

  return (
    <Stack spacing={1} sx={{ width: '100%', maxWidth: '900px' }}>
      <Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
        <Box sx={{ mx: 1 }}>{getAvatar()}</Box>
        <Stack sx={{ width: '100%' }}>
          <Stack direction='row'>
            <Typography
              variant='subtitleMedium'
              sx={{ fontSize: '18px', padding: '0' }}>
              {message.role === 'human' ? 'You' : 'GigaWattson'}
            </Typography>
          </Stack>

          <Typography
            component={'div'}
            whiteSpace='normal'
            display='block'
            sx={{
              marginBottom: 2,
              lineBreak: 'loose',
              maxWidth: '100%',
              '& p': { margin: '0 0 0.5em 0' },
              '& ol, & ul': { padding: '0 0 0 15px' },
              '& a ': {
                color: 'var(--nv-palette-success-dark)',
              },
              '& pre': {
                whiteSpace: message.role === 'human' ? 'pre-line' : 'pre-wrap',
                wordWrap: 'normal',
              },

              '& code': {
                lineBreak: 'anywhere',
              },
            }}>
            {message.relatedToolMessages && (
              <ToolUseSection toolMessages={message.relatedToolMessages} />
            )}

            {isLoading ? (
              <Box ref={streamingContentRef} />
            ) : (
              <Box>
                {parse(
                  parseMdSnapshot(message.content, {
                    syntaxHighlighter: highlighter,
                    showCodeBlockCopyButton: false,
                  }),
                )}
              </Box>
            )}

            {!isLoading && message.created_at && (
              <Typography
                variant='captionSmall'
                display='block'
                sx={{ opacity: 0.5 }}>
                {new Date(message.created_at).toLocaleString('sv-SE')}{' '}
              </Typography>
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Message
