import React from 'react'

export default ({ ...props }) => (
  <svg
    {...props}
    className='icon'
    x='0px'
    y='0px'
    viewBox='0 0 64 64'
    height={20}>
    <title>Close icon</title>
    <g>
      <path
        fill='#000000'
        d='M51.18,55.29c-0.89,0-1.79-0.34-2.47-1.02L9.41,14.97c-1.37-1.37-1.37-3.58,0-4.94
          c1.37-1.37,3.58-1.37,4.94,0l39.29,39.3c1.37,1.37,1.37,3.58,0,4.94C52.97,54.95,52.07,55.29,51.18,55.29z'
      />
    </g>
    <g>
      <path
        fill='#000000'
        d='M11.89,55.29c-0.89,0-1.79-0.34-2.47-1.02c-1.37-1.37-1.37-3.58,0-4.94l39.29-39.3
          c1.37-1.37,3.58-1.37,4.94,0c1.37,1.36,1.37,3.58,0,4.94l-39.29,39.3C13.67,54.95,12.78,55.29,11.89,55.29z'
      />
    </g>
  </svg>
)
