import CloseIcon from '@mui/icons-material/Close'
import TableChartIcon from '@mui/icons-material/TableChart'
import { highlighter } from '@nlux/highlighter'
import { parseMdSnapshot } from '@nlux/markdown'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@northvolt/ui'
import { motion } from 'framer-motion'
import parse from 'html-react-parser'
import { useRef, useState } from 'react'
import { format } from 'sql-formatter'
import type { ToolCallWithOutput } from './ToolUseSection'
import '@nlux/highlighter/dark-theme.css'
import { TableBody } from '@mui/material'

interface WombatQueryCardProps {
  toolData: ToolCallWithOutput
}

export default function WombatQueryCard({ toolData }: WombatQueryCardProps) {
  const [detailOpen, setDetailOpen] = useState<boolean>(false)
  const descriptionElementRef = useRef<HTMLElement>(null)

  function getFormattedSqlQuery() {
    const rawQuery = toolData.args.sql_query || 'unknown'
    let fomattedQuery: string
    try {
      fomattedQuery = format(rawQuery, { language: 'spark' })
    } catch (error) {
      fomattedQuery = rawQuery
    }
    // biome-ignore lint/style/useTemplate: <explanation>
    const mdQuery = '```sql\n' + fomattedQuery + '\n```'
    return mdQuery
  }

  function outputTable() {
    if (toolData.output_json) {
      if (Array.isArray(toolData.output_json)) {
        return (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {Object.keys(toolData.output_json[0]).map(key => (
                    <TableCell key={key} align='left'>
                      {key}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {toolData.output_json.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      {Object.entries(row).map(([key, value]) => (
                        <TableCell key={key} align='left'>
                          {JSON.stringify(value, null, 2)}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )
      } else if (typeof toolData.output_json === 'object') {
        return JSON.stringify(toolData.output_json, null, 2)
      }
    } else if (toolData.output_text) {
      return toolData.output_text
    }
    return '...'
  }

  const mdQuery = getFormattedSqlQuery()
  const resultLength = toolData.output_json ? toolData.output_json.length : 0

  return (
    <Box
      component={motion.div}
      sx={{
        p: 1,
        border: 'solid 1px var(--nv-palette-grey-700)',
        borderRadius: '10px',
        height: '100%',
        maxHeight: '120px',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'var(--nv-palette-grey-900)',
        },
      }}
      initial={{ opacity: 0, translateY: -25 }}
      animate={{ translateY: 0, opacity: 1, from: 0, to: 1 }}
      transition={{ delay: 0.1, ease: 'easeIn' }}
      onClick={() => setDetailOpen(!detailOpen)}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TableChartIcon fontSize='small' />
        <Typography
          variant='subtitleSmall'
          sx={{ fontWeight: 'bold', ml: 0.5, lineHeight: 'normal' }}>
          Querying Wombat Dataset
        </Typography>
      </Box>
      {toolData.output_text && (
        <Box sx={{ pt: 1 }}>
          <Typography
            variant='subtitleSmall'
            sx={{
              color: 'var(--nv-palette-grey-400)',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
            }}
            style={{ WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}>
            {toolData.output_text}
          </Typography>
        </Box>
      )}
      {resultLength > 0 && (
        <Box sx={{ pt: 1 }}>
          <Typography
            variant='subtitleSmall'
            sx={{ color: 'var(--nv-palette-grey-400)' }}>
            Got {resultLength} result(s)
          </Typography>
        </Box>
      )}

      <Dialog
        open={detailOpen}
        onClose={() => setDetailOpen(false)}
        scroll='paper'
        fullWidth
        maxWidth='md'>
        <DialogTitle sx={{ alignItems: 'center', display: 'flex' }}>
          <TableChartIcon fontSize='medium' sx={{ mr: 2 }} />
          Querying Wombat Dataset
          <IconButton
            onClick={() => setDetailOpen(false)}
            sx={{ position: 'absolute', right: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant='subtitleMedium'>Query</Typography>
          <Box sx={{ borderBottom: 'solid 1px var(--nv-palette-grey-700)' }}>
            {parse(
              parseMdSnapshot(mdQuery, {
                syntaxHighlighter: highlighter,
                showCodeBlockCopyButton: false,
              }),
            )}
          </Box>
          <Typography variant='subtitleMedium'>Outputs</Typography>
          <Box
            id='scroll-dialog-description'
            ref={descriptionElementRef}
            tabIndex={-1}
            sx={{ p: 2, wordWrap: 'break-word' }}>
            {outputTable()}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
