import { highlighter } from '@nlux/highlighter'
import { parseMdSnapshot } from '@nlux/markdown'
import { Box, List, ListItem, ListItemText, Typography } from '@northvolt/ui'
import '@nlux/highlighter/dark-theme.css'
import parse from 'html-react-parser'

interface OutputDocumentProps {
  document: {
    metadata?: Map<string, string>
    page_content?: string
  }
  outputIdx: number
}

export default function OutputDocument({
  document,
  outputIdx,
}: OutputDocumentProps) {
  const metaMap = document.metadata || new Map()
  const content = document.page_content || JSON.stringify(document, null, 2)
  return (
    <Box
      sx={{
        border: 'solid 1px var(--nv-palette-grey-700)',
        borderRadius: '10px',
        p: 2,
        width: '100%',
      }}>
      <Typography variant='subtitleMedium'>Output {outputIdx}</Typography>
      <List>
        {Object.entries(metaMap).map(([key, value]) => (
          <ListItem
            key={key}
            disablePadding
            sx={{ borderBottom: 'solid 1px var(--nv-palette-grey-700)', p: 1 }}>
            <ListItemText primary={key} />
            <ListItemText
              primary={JSON.stringify(value, null, 2)}
              sx={{ textAlign: 'right' }}
            />
          </ListItem>
        ))}
      </List>
      <Box sx={{ p: 0, wordBreak: 'break-word' }}>
        {parse(
          parseMdSnapshot(content, {
            syntaxHighlighter: highlighter,
            showCodeBlockCopyButton: false,
          }),
        )}
      </Box>
    </Box>
  )
}
