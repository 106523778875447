import { AIMessageChunk } from '@langchain/core/messages'
import type { EventSourceMessage } from '@microsoft/fetch-event-source'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { getIdToken } from '@northvolt/snowflake'
import type { AxiosResponse } from 'axios'
import type { Assistant, Message as TMessage } from 'client/model'
import { useGetTopAssistant, useGetUserChat } from 'client/wattson-client'
import { useCallback, useEffect, useRef, useState } from 'react'

export interface StreamCallback {
  onSuccess?: () => void
  onChunk?: (chunk: AIMessageChunk) => void
  onError?: (error: any) => void
}

export type StreamingChatMessage = TMessage & {
  isLoading?: boolean
  relatedToolMessages?: TMessage[]
}

export function useChat(
  existingChatId?: string,
  callbacks: StreamCallback = {},
) {
  const assistantLoader = useGetTopAssistant<AxiosResponse<Assistant>>()
  const [chatId, setChatId] = useState<string | undefined>(existingChatId)
  const [messages, setMessages] = useState<StreamingChatMessage[]>([])
  const [assistant, setAssistant] = useState<string | undefined>(undefined)
  const [controller, setController] = useState<AbortController | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [currentTime, setCurrentTime] = useState<Date>(new Date())

  const chatHistoryLoader = useGetUserChat(chatId ? chatId : '')

  useEffect(() => {
    if (assistantLoader.data?.data) {
      const { path } = assistantLoader.data?.data || {}
      setAssistant(path)
    }
  }, [assistantLoader.data])

  useEffect(() => {
    if (existingChatId) {
      setChatId(existingChatId)
      chatHistoryLoader.refetch()
    }
  }, [existingChatId])

  useEffect(() => {
    if (chatHistoryLoader?.data) {
      const data = chatHistoryLoader.data?.data
      if (data.messages && !isLoading) setMessages(data.messages)
      setAssistant(chatHistoryLoader.data?.data?.assistant)
    }
  }, [chatHistoryLoader.data])

  const chunkRef = useRef(callbacks.onChunk)
  chunkRef.current = callbacks.onChunk

  const successRef = useRef(callbacks.onSuccess)
  successRef.current = callbacks.onSuccess

  const errorRef = useRef(callbacks.onError)
  errorRef.current = callbacks.onError

  function handleStreamEvent(rawMessage: EventSourceMessage) {
    if (rawMessage.event === 'end') {
      setController(null)
    } else if (rawMessage.event === 'data') {
      try {
        const message = JSON.parse(rawMessage.data)
        // If we started a chat without a chat ID, we can find it in the metadata of any stream event
        if (!chatId) {
          setChatId(message.metadata.chat_id)
        }
        if (
          message.event === 'on_llm_stream' ||
          message.event === 'on_chat_model_stream'
        ) {
          if (message.data.chunk) {
            const chunk = new AIMessageChunk(message.data.chunk)
            chunkRef.current?.(chunk)
          }
        } else if (message.event === 'on_tool_end') {
          const toolCallMessage: TMessage = {
            id: currentTime.getTime() + 1,
            created_at: currentTime.toISOString(),
            role: 'ai',
            content: '',
            tool_calls: [
              {
                call_message_id: currentTime.getTime() + 1,
                call_id: message.data.output.tool_call_id,
                name: message.data.output.name,
                args: message.data.input,
              },
            ],
          }
          const toolOutputMessage: TMessage = {
            ...message.data.output,
            id: currentTime.getTime() + 2,
            created_at: currentTime.toISOString(),
            role: 'tool',
          }
          setMessages(prevMessages => [
            ...prevMessages,
            toolCallMessage,
            toolOutputMessage,
          ])
        }
      } catch (error) {
        console.error('Error parsing message', error)
      }
    }
  }

  const sendMessage = useCallback(
    async (input: string) => {
      const currentTime = new Date()
      const aiTime = new Date()
      aiTime.setSeconds(aiTime.getSeconds() + 1)

      const userMessage = {
        id: currentTime.getTime(),
        content: input,
        role: 'human',
        created_at: currentTime.toISOString(),
        isLoading: false,
      }
      const streamingResponse = {
        id: aiTime.getTime(),
        content: ' ',
        role: 'ai',
        created_at: aiTime.toISOString(),
        isLoading: true,
      }
      setMessages(prevMessages => [
        ...prevMessages,
        userMessage,
        streamingResponse,
      ])
      setCurrentTime(currentTime)

      const abortController = new AbortController()
      setController(abortController)
      setIsLoading(true)

      const url = `${import.meta.env.VITE_API_URI}api/chat/${
        assistant ?? 'foundation'
      }/stream_events`
      const body = JSON.stringify({
        input,
        config: { configurable: { chat_id: chatId } },
      })
      const headers = {
        Authorization: `Bearer ${getIdToken()}`,
        'Content-Type': 'application/json',
      }

      try {
        await fetchEventSource(url, {
          signal: abortController.signal,
          method: 'POST',
          headers: headers,
          body: body,
          onmessage: handleStreamEvent,
          onclose() {
            setController(null)
            setIsLoading(false)
            chatHistoryLoader.refetch()
            successRef.current?.()
          },
          onerror(error) {
            setController(null)
            setIsLoading(false)
            errorRef.current?.(error)
            throw error
          },
          openWhenHidden: true,
        })
      } catch (error) {
        console.error('Error sending message', error)
        setController(null)
        setIsLoading(false)
        errorRef.current?.(error)
      }
    },
    [successRef, errorRef, chunkRef, setMessages, assistant, chatId],
  )

  const stopStream = useCallback(() => {
    controller?.abort()
    setController(null)
    setIsLoading(false)
  }, [controller])

  return {
    sendMessage: sendMessage,
    stopStream: controller ? stopStream : undefined,
    isLoading,
    messages,
    chatHistoryLoader,
  }
}

// Quite Unsatisfactory Data Structure. Trade-off for aligning withLangChain chat histoy...
export function groupMessages(messages: StreamingChatMessage[]) {
  const groupingFun = (
    acc: StreamingChatMessage[],
    message: StreamingChatMessage,
  ) => {
    if (
      message.role === 'tool' ||
      (message.role === 'ai' && (message.tool_calls ?? []).length > 0)
    ) {
      const lastMessage = acc[acc.length - 1]
      if (lastMessage && lastMessage.role === 'ai') {
        const updatedLastMessage = {
          ...lastMessage,
          relatedToolMessages: [
            message,
            ...(lastMessage.relatedToolMessages || []),
          ],
        }
        acc[acc.length - 1] = updatedLastMessage
      }
    }
    if (
      message.role === 'human' ||
      (message.role === 'ai' && message.content !== '')
    ) {
      acc.push(message)
    }
    return acc
  }

  return messages
    .sort((a, b) => {
      if (a.isLoading) return 1
      if (b.isLoading) return -1
      if (a.created_at === b.created_at) return a.id - b.id
      return Number(a.created_at) - Number(b.created_at)
    })
    .reduceRight(groupingFun, [])
    .reverse()
}
