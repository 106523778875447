import { useEffect } from 'react'

import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography,
} from '@northvolt/ui'

import ClearIcon from '@mui/icons-material/Clear'
import {
  useDeleteChat,
  useGetUserAssistants,
  useGetUserHistory,
} from 'client/wattson-client'

import type { Chat } from 'client/model/chat'

import { LinearProgress } from '@mui/material'
import { dateSorting } from './grouping'

import { useParams } from 'react-router-dom'

export default function History({
  handleDrawerClose,
}: {
  handleDrawerClose: () => void
}) {
  const params = useParams()
  const chatDelete = useDeleteChat()
  const historyLoader = useGetUserHistory()
  const assistantLoader = useGetUserAssistants()

  const assistants = assistantLoader?.data?.data || []

  const handleDeleteChat = (id: string) => {
    chatDelete.mutate({ chatId: id })
    if (params.chatId === id) {
      window.location.replace('/')
    }
    handleDrawerClose()
  }

  const goToChat = (id: string) => {
    window.location.replace(`/chat/${id}`)
    handleDrawerClose()
  }

  useEffect(() => {
    historyLoader.refetch()
  }, [params])

  useEffect(() => {
    if (chatDelete.isSuccess) {
      historyLoader.refetch()
    }
  }, [chatDelete.isSuccess])

  if (historyLoader.isLoading) {
    return (
      <LinearProgress
        sx={{
          backgroundColor: 'inherit',
          '& .MuiLinearProgress-bar': {
            backgroundColor: 'var(--nv-palette-success-main)',
          },
        }}
      />
    )
  }

  if (historyLoader.isError) {
    return (
      <Typography variant='subtitleSmall' textAlign={'center'}>
        Failed to Load Chat History
      </Typography>
    )
  }

  const history = historyLoader?.data?.data || []
  const { today, yesterday, prev7Days, prev30Days } = dateSorting(history)

  function renderItems(items: Chat[]) {
    return items.map((item: Chat) => {
      const isActive = params?.chatId === item.id.toString()

      const title =
        assistants.find(a => a.path === item.assistant)?.name || 'Assistant'
      return (
        <ListItem disablePadding key={`chat-${item.id}`}>
          <Box width={'100%'} display={'flex'} flexDirection={'row'}>
            <ListItemButton
              onClick={() => goToChat(item.id.toString())}
              sx={{
                width: '100%',
                backgroundColor: isActive
                  ? 'var(--nv-palette-grey-800)'
                  : 'transparent',
              }}>
              <Stack sx={{ maxWidth: '100%' }}>
                <Typography
                  noWrap
                  variant='subtitleSmall'
                  textOverflow='ellipsis'
                  title={item.title || 'New Chat'}>
                  {item.title || 'New Chat'}
                </Typography>
                <Typography
                  noWrap
                  variant='captionSmall'
                  textOverflow='ellipsis'>
                  {title}
                </Typography>
              </Stack>
            </ListItemButton>
            <ListItemButton
              sx={{
                backgroundColor: isActive
                  ? 'var(--nv-palette-grey-800)'
                  : 'transparent',
              }}
              onClick={() => handleDeleteChat(item.id.toString())}>
              <ClearIcon sx={{ fontSize: 12 }} />
            </ListItemButton>
          </Box>
        </ListItem>
      )
    })
  }

  return (
    <Box sx={{ paddingBottom: 10 }}>
      {today && today.length > 0 && (
        <List sx={{ paddingBottom: 1 }}>
          <ListItem>
            <Typography>Today</Typography>
          </ListItem>
          {renderItems(today)}
        </List>
      )}

      {yesterday && yesterday.length > 0 && (
        <List>
          <ListItem>
            <Typography>Yesterday</Typography>
          </ListItem>
          {renderItems(yesterday)}
        </List>
      )}
      {prev7Days && prev7Days.length > 0 && (
        <List>
          <ListItem>
            <Typography>Previous 7 Days</Typography>
          </ListItem>
          {renderItems(prev7Days)}
        </List>
      )}
      {prev30Days && prev30Days.length > 0 && (
        <List>
          <ListItem>
            <Typography>Previous 30 Days</Typography>
          </ListItem>
          {renderItems(prev30Days)}
        </List>
      )}
    </Box>
  )
}
