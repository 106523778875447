import { getUser } from '@northvolt/snowflake'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'

import { Box, Typography } from '@mui/material'
import Layout from 'components/Layout'
import mixpanel from 'mixpanel-browser'
import { type ReactNode, useEffect } from 'react'
import { useRouteError } from 'react-router-dom'

export default (): ReactNode => {
  const error = useRouteError()
  useEffect(() => {
    const user = getUser()

    if (!user) return

    mixpanel.identify(user.email)
    mixpanel.people.set({
      $name: `${user.given_name} ${user.family_name}`,
      $email: user.email,
      email: user.email,
    })
    mixpanel.people.set_once('First Login Date', new Date())
  }, [])

  //@ts-ignore
  const statusText = error?.statusText || 'Error'
  //@ts-ignore
  const errorData = error?.data || 'An error occurred'
  return (
    <Layout>
      <Box
        sx={{
          width: '100%',
          height: '50vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Typography marginBottom={2} align='center' variant='headlineLarge'>
          🤯
        </Typography>
        <Typography marginBottom={2} align='center' variant='headlineMedium'>
          Oh Noo! {statusText}
        </Typography>
        <Typography marginBottom={2} align='center' variant='headlineMedium'>
          {errorData}
        </Typography>
      </Box>

      <Analytics />
      <SpeedInsights />
    </Layout>
  )
}
