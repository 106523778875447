import { ForceRefresh, getIdToken, getTokens } from '@northvolt/snowflake'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import axios from 'axios'
import type React from 'react'
import { useEffect, useState } from 'react'

export default ({ children }: { children: React.ReactNode }) => {
  const [currentToken, setCurrentToken] = useState(getIdToken())
  axios.defaults.baseURL = import.meta.env.VITE_API_URI
  axios.defaults.headers.common.Authorization = `Bearer ${currentToken}`

  useEffect(() => {
    axios.defaults.headers.common.Authorization = `Bearer ${currentToken}`
  }, [currentToken])

  // Check the expiry time of the token every re-focus of the window (and refresh if it is about to expire)
  window.addEventListener('focus', () => {
    const tokens: any = getTokens() // Type is wrong in the snowflake package :(
    if (tokens !== null) {
      const { created, expires_in } = tokens
      const secondsUntilTokenExpires =
        (created + expires_in * 1000 - Date.now()) / 1000
      if (secondsUntilTokenExpires < 60) {
        ForceRefresh()
        const newToken = getIdToken()
        setCurrentToken(newToken)
      }
    }
  })

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 3,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

// Always set the Authorization header to the current token
axios.interceptors.request.use(
  async config => {
    const token = getIdToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)
